import { useNavigate } from "react-router-dom";
import Not from "../../../assets/images/404.png";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-3 h-[calc(100vh-150px)] items-center justify-center px-4">
      <img src={Not} alt="" />
      <div className="items-center font-medium text-lg">
        Sorry, but this page doesn't exist
      </div>
      <div className="text-center font-normal capitalize">
        Maybe you would like to go back to our{" "}
        <span className="text-[#FA8151]" onClick={() => navigate("/")}>
          homepage
        </span>{" "}
        or{" "}
        <span className="text-[#FA8151]" onClick={() => navigate("/help")}>
          contact us ?
        </span>
      </div>
    </div>
  );
};

export default NotFound;
