import { createSlice } from "@reduxjs/toolkit";
import { Chat } from "./types";

const token = localStorage.getItem("zego-token") || "";
const initialStateValue: Chat = {
  isLogged: false,
  token,
  isLoading: true,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: { value: initialStateValue },
  reducers: {
    setChat: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setChat } = chatSlice.actions;
export default chatSlice.reducer;
