import { createSlice } from "@reduxjs/toolkit";
import { FavouritePropType } from "./types";

const initialStateValue: FavouritePropType = {
  favourite: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  favouriteEstablishment: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  favouriteEstablishmentLoading: true,
  favouriteLoading: true,
};

export const favouriteSlice = createSlice({
  name: "favourite",
  initialState: { value: initialStateValue },
  reducers: {
    setFavourite: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    setFavouriteEstablishment: (state, action) => {
      state.value.favouriteEstablishment = {
        ...state.value.favouriteEstablishment,
        ...action.payload,
      };
    },
    toggleFavorite: (state, action) => {
      const item = state.value.favourite.data.findIndex(
        (item) => item.item.id === action.payload
      );
      if (item !== -1) {
        state.value.favourite.data.splice(item, 1);
      }
    },
    toggleFavoriteEstablishment: (state, action) => {
      const item = state.value.favouriteEstablishment.data.findIndex(
        (item) => item.establishment.id === action.payload
      );
      if (item !== -1) {
        state.value.favouriteEstablishment.data.splice(item, 1);
      }
    },
  },
});

export const {
  setFavourite,
  toggleFavorite,
  setFavouriteEstablishment,
  toggleFavoriteEstablishment,
} = favouriteSlice.actions;
export default favouriteSlice.reducer;
