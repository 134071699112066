import { lazy } from "react";
import NotFound from "../../components/ui/page/NotFound";
const Orders = lazy(() => import("../../pages/Orders"));
const Wallet = lazy(() => import("../../pages/Wallet"));
const Chat = lazy(() => import("../../pages/Chat"));
const Favourite = lazy(() => import("../../pages/Favourite"));
const Settings = lazy(() => import("../../pages/Settings"));

const controlPanel = [
  {
    path: "orders/*",
    component: Orders,
  },
  {
    path: "favourite/*",
    component: Favourite,
  },
  {
    path: "wallet/*",
    component: Wallet,
  },
  {
    path: "inbox/*",
    component: Chat,
  },
  {
    path: "settings/*",
    component: Settings,
  },

  {
    path: "*",
    component: NotFound,
  },
];

export default controlPanel;
