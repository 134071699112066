import { ButtonPropType } from "./types";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";

const Button = ({
  onClick,
  label,
  color,
  icon,
  hasIcon,
  loading,
  disabled,
  full,
}: ButtonPropType) => {
  return (
    <button
      className={` flex justify-center items-center rounded-[3px]  h-11   text-xs font-medium cursor-pointer py-4 px-5 gap-3 ${
        full ? "w-fit px-10" : "w-full"
      } ${
        color === "orange"
          ? "bg-[#FA8151] text-white"
          : color === "blue"
          ? " bg-blue text-white"
          : color === "wine"
          ? " bg-[#A62100] text-white"
          : color === "outline"
          ? " bg-white text-primary border border-primary"
          : "bg-[#EFE8E5] text-black"
      } ${disabled ? "bg-[#DADADA] text-[#727272]" : ""}`}
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}
      {hasIcon && <img src={icon} alt="" className="w-[25px] h-[25px] mr-2" />}
      <span className="">{label}</span>
    </button>
  );
};

export default Button;
