import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import Button from "../ui/button";
import Popup from "./Popup";
import { setCart } from "../../features/cart/reducer";
import notify from "../../utils/notify";

import Authentication from "../../utils/apiServices/authentication";
import { login } from "../../features/login/reducer";
import FormButton from "../form/formButton/FormButton";
import FormInput from "../form/formInput/FormInput";

type PropType = {
  closeModal: () => void;
};
const LoginPopup = ({ closeModal }: PropType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const device_token = localStorage.getItem("device_token") || "";
  const [formLoading, setFormLoading] = useState(false);
  const { register, formState, handleSubmit } = useForm();
  const onLogin: SubmitHandler<FieldValues> = (formData) => {
    setFormLoading(true);
    Authentication.login({
      email: formData.email,
      password: formData.password,
      device_token,
    })
      .then((res) => {
        if (res.success) {
          const { data } = res;
          dispatch(
            login({
              id: data.user.id,
              country: data.user.country,
              firstname: data.user.firstname,
              lastname: data.user.lastname,
              phone: data.user.phone,
              email: data.user.email,
              image: data.user.image,
              is_phone_verified: data.user.is_phone_verified,
              email_verified_at: data.user.email_verified_at,
              interest: data.user.interest,
              device_token: data.user.device_token,
              status: data.user.status,
              order_count: data.user.order_count,
              login_medium: data.user.login_medium,
              social_id: data.user.social_id,
              zone_id: data.user.zone_id,
              wallet_balance: data.user.wallet_balance,
              ref_code: data.user.ref_code,
              ref_by: data.user.ref_by,
              temp_token: data.user.temp_token,
              account_status: data.user.account_status,
              created_at: data.user.created_at,
              updated_at: data.user.updated_at,
              delete_at: data.user.delete_at,
              access_token: data.token.token,
              loggedIn: true,
              remember_me: true,
            })
          );
          setFormLoading(false);
          notify("success", res.message);
          dispatch(
            setCart({
              isCheckout: false,
            })
          );
          dispatch(
            setCart({
              isActive: false,
            })
          );
          //   navigate("/checkout");
        } else {
          setFormLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setFormLoading(false);
        notify("error", "Check internet connection");
      });
  };

  return (
    <Popup onClose={closeModal} title="Sign In" small>
      <div className="md:w-[400px]">
        <div className="flex flex-col items-center gap-4 p-4 md:px-8">
          <div className="text-sm text-greyB text-center">
            Sign in to your account to continue with your order
          </div>
          <form
            className="flex flex-col w-full justify-between  bg-white "
            onSubmit={handleSubmit(onLogin)}
          >
            <div className="">
              <FormInput
                label="Email Address"
                name="email"
                placeholder="Enter your email address"
                color="white"
                type="email"
                register={register}
                formState={formState}
                rules={{ required: "Enter Email" }}
              />
              <FormInput
                label="Password"
                name="password"
                placeholder="Enter password"
                color="white"
                type="password"
                register={register}
                formState={formState}
                rules={{ required: "Enter Password" }}
              />
            </div>
            <FormButton error={false} label="Login" loading={formLoading} />
            <div className=" gap-4 text-center text-sm">
              Not Registered?{" "}
              <span
                className="text-blue font-medium cursor-pointer underline underline-offset-2"
                onClick={() => navigate("/signup")}
              >
                Create an account
              </span>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
};

export default LoginPopup;
