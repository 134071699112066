import { lazy } from "react";
import NotFound from "../../components/ui/page/NotFound";

const ControlPanel = lazy(() => import("../../layout/panel/index"));

const Landing = lazy(() => import("../../pages/Landing"));
const Home = lazy(() => import("../../pages/Home"));
const Store = lazy(() => import("../../pages/Store"));
const Search = lazy(() => import("../../pages/Search"));
const Restaurant = lazy(() => import("../../pages/Restaurant"));
const Supermarket = lazy(() => import("../../pages/Supermarket"));
const Checkout = lazy(() => import("../../pages/Checkout"));
const Login = lazy(() => import("../../pages/Login"));
const Signup = lazy(() => import("../../pages/Signup"));
const RiderKyc = lazy(() => import("../../pages/RiderKyc"));
const ForgotPassword = lazy(() => import("../../pages/ForgotPassword"));

const About = lazy(() => import("../../pages/Landing/About"));
const Features = lazy(() => import("../../pages/Landing/Features"));
const Contact = lazy(() => import("../../pages/Landing/Contact"));
const Privacy = lazy(() => import("../../pages/Landing/Privacy"));
const Terms = lazy(() => import("../../pages/Landing/Terms"));
const Cookie = lazy(() => import("../../pages/Landing/Cookie"));
const Shipping = lazy(() => import("../../pages/Landing/Shipping"));
const Refund = lazy(() => import("../../pages/Landing/Refund"));
const Cancellation = lazy(() => import("../../pages/Landing/Cancellation"));
const Faq = lazy(() => import("../../pages/Landing/Faq"));
const Vendor = lazy(() => import("../../pages/Landing/Vendor"));
const Rider = lazy(() => import("../../pages/Landing/Rider"));

const pagesRoute = [
  {
    path: "/",
    component: Landing,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/establishment",
    component: Vendor,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/riders",
    component: Rider,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/about",
    component: About,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/features",
    component: Features,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/contact",
    component: Contact,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/privacy-policy",
    component: Privacy,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/terms-conditions",
    component: Terms,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/cookie-policy",
    component: Cookie,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/shipping-policy",
    component: Shipping,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/return-policy",
    component: Refund,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/cancellation-policy",
    component: Cancellation,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/faq",
    component: Faq,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/home",
    component: Home,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/signup",
    component: Signup,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/driver/kyc/:id",
    component: RiderKyc,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/search/*",
    component: Search,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/store/*",
    component: Store,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/restaurant/*",
    component: Restaurant,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/supermarket/*",
    component: Supermarket,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      redirectUrl: "/home",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/checkout/*",
    component: Checkout,
    meta: {
      redirectUrl: "/home",
      protectedRoute: true,
      role: "",
    },
  },
  // {
  //   path: "/*",
  //   component: NotFound,
  //   meta: {
  //     redirectUrl: "/home",
  //     protectedRoute: true,
  //     role: "user",
  //   },
  // },
  {
    path: "/*",
    component: ControlPanel,
    meta: {
      redirectUrl: "/home",
      protectedRoute: true,
      role: "user",
    },
  },
];

export default pagesRoute;
