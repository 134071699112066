import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "./types";

const initialStateValue: UserType = {
  country: null,
  id: "",
  user_id: 0,
  firstname: "",
  lastname: "",
  phone: 0,
  email: "",
  image: "",
  is_phone_verified: false,
  email_verified_at: "",
  interest: null,
  device_token: "",
  status: false,
  order_count: 0,
  login_medium: null,
  social_id: null,
  zone_id: null,
  wallet_balance: "",
  ref_code: null,
  ref_by: null,
  temp_token: null,
  account_status: "",
  created_at: "",
  updated_at: "",
  delete_at: "",
  loggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      // Set Token
      sessionStorage.setItem("accessToken", action.payload.access_token);
      if (action.payload.remember_me) {
        localStorage.setItem("accessToken", action.payload.access_token);
      }
      state.value = action.payload;
    },
    setUser: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.value = initialStateValue;
    },
  },
});

export const { login, setUser, logout } = userSlice.actions;
export default userSlice.reducer;
