/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  selected: localStorage.getItem("language") || "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState: { value: initialStateValue },
  reducers: {
    setLanguage: (state, action) => {
      state.value = { ...state.value, ...action.payload };
      localStorage.setItem("language", action.payload.selected);
    },

    resetLanguage: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setLanguage, resetLanguage } = languageSlice.actions;

export default languageSlice.reducer;
