import { FormButtonProps } from "./types";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";

const FormButton = ({ label, error, loading, small }: FormButtonProps) => {
  return (
    <button
      disabled={loading || error}
      className={` flex justify-center items-center rounded-[3px] bg-primary h-12 border-none text-white text-[14.4px] w-full font-medium cursor-pointer py-3 my-5 ${
        error ? "cursor-not-allowed text-black bg-gray-500" : ""
      } ${loading ? "cursor-not-allowed w-full" : ""}${small ? "w-full" : ""}`}
      type="submit"
    >
      {loading && <Spinner className="w-[30px] h-[30px] mr-2 stroke-white" />}
      {label}
    </button>
  );
};

export default FormButton;
