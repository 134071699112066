import baseURL from "../baseUrl";
import { timeZone } from "../constants";
import getAccessToken from "../../accessToken";

import {
  UpdatePasswordParameters,
  UpdateProfileImageParameter,
  UpdateProfileParameters,
} from "./types";

class Profile {
  async getProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile`, {
      method: "get",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }
  async updateProfile(data: UpdateProfileParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/profile-update`, {
      method: "put",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async changePasswords(data: UpdatePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/update-password`, {
      method: "post",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async setProfilePicture(data: UpdateProfileImageParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/user/profile/updateImage`, {
      method: "post",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async deleteProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/delete`, {
      method: "delete",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  }
}

const instance = new Profile();

export default instance;
