import baseURL from "../baseUrl";
import { timeZone } from "../constants";
import getAccessToken from "../../accessToken";
import {
  LoginParameters,
  ResendOtpParameter,
  CheckResetPasswordParameter,
  SignupPatrameters,
  VerifyParameter,
  ResetPasswordParameter,
} from "./types";

class Authentication {
  async signupInitialize(data: SignupPatrameters) {
    const response = await fetch(`${baseURL}/customer/auth/register`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async verify(data: VerifyParameter) {
    const response = await fetch(`${baseURL}/customer/auth/verify-account`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async sendPasswordChangeVerification(data: CheckResetPasswordParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/send-password-verification`,
      {
        method: "post",
        headers: {
          timezone: timeZone,
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async verifyChangePassword(data: {email: string, otp: string}) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/passwordchange-verification`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async checkResetPassword(data: CheckResetPasswordParameter) {
    const response = await fetch(
      `${baseURL}/customer/auth/verify-password-email`,
      {
        method: "post",
        headers: {
          timezone: timeZone,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async resetPassword(data: ResetPasswordParameter) {
    const response = await fetch(
      `${baseURL}/customer/auth/reset-password`,
      {
        method: "post",
        headers: {
          timezone: timeZone,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async resendOTP(data: ResendOtpParameter) {
    const response = await fetch(`${baseURL}/auth/resend_otp`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async login(data: LoginParameters) {
    const response = await fetch(`${baseURL}/customer/auth/login`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async logout() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/logout`, {
      method: "post",
      headers: {
        timezone: timeZone,
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.json();
  }
}
const instance = new Authentication();
export default instance;
