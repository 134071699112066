import { createSlice } from "@reduxjs/toolkit";
import { ProfilePropType } from "./types";
const home_address =
  localStorage.getItem("home_address") !== null
    ? localStorage.getItem("home_address")!
    : "";
const localStorageCord = localStorage.getItem("cord");
const initialCord = localStorageCord
  ? JSON.parse(localStorageCord)
  : { lat: 0, lng: 0 };

const initialStateValue: ProfilePropType = {
  address: [],
  isAddress: false,
  addressLoading: true,
  home_address,
  cord: initialCord,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: { value: initialStateValue },
  reducers: {
    setProfile: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    setHomeAddress: (state, action) => {
      state.value.home_address = action.payload;
      localStorage.setItem("home_address", state.value.home_address);
    },
    setCord: (state, action) => {
      state.value.cord = action.payload;
      localStorage.setItem(
        "cord",
        JSON.stringify({ lat: state.value.cord.lat, lng: state.value.cord.lng })
      );
    },
  },
});

export const { setProfile, setHomeAddress, setCord } = profileSlice.actions;
export default profileSlice.reducer;
