import { createSlice } from "@reduxjs/toolkit";
import { NotificationProp } from "./type";

const initialStateValue: NotificationProp = {
  notifications: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  notificationsLoading: true,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: { value: initialStateValue },
  reducers: {
    setNotification: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
