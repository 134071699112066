import { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { getFirebaseToken, onMessageListener } from "../../FirebaseConfig";
import notify from "../../utils/notify";

function Notification() {
  // To store notification data from firebase
  const [notification, setNotification] = useState<{
    title: string;
    body: string;
  }>({ title: "", body: "" });

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken: string | undefined) => {
      if (firebaseToken) {
        localStorage.setItem("device_token", firebaseToken);
      }
    });
  };

  // Need this handle FCM token generation when a user manually blocks or allows notification
  useEffect(() => {
    if ("Notification" in window) {
      window.Notification.requestPermission().then((permission) => {
        if ("Notification" in window && permission === "granted") {
          handleGetFirebaseToken();
          const unsubscribe = onMessageListener().then((payload: any) => {
            setNotification({
              title: payload?.notification?.title,
              body: payload?.notification?.body,
            });
            toast.success(
              `${payload?.notification?.title}, ${payload?.notification?.body}`,
              {
                duration: 30000,
                style: {
                  border: "1px solid #1EC677",
                  padding: "10px 25px",
                  color: "#1EC677",
                },
                iconTheme: {
                  primary: "#1EC677",
                  secondary: "#00190D",
                },
              }
            );
          });

          return () => {
            unsubscribe.catch((err: Error) => notify("error", err.message));
          };
        }
      });
    }
  }, []);

  return (
    <div>
      <Toaster />
    </div>
  );
}

export default Notification;
