import { useState } from "react";
import { ReactComponent as EyeOpen } from "../../../assets/icons/eyeOpen.svg";
import { ReactComponent as EyeClose } from "../../../assets/icons/eyeClose.svg";

import { FormInputProps } from "./types";

const FormInput = ({
  color,
  label, // optional
  subLabel, // optional
  bottomLabel, // optional
  type,
  register,
  name,
  placeholder, // optional
  defaultValue, // optional
  formState,
  rules,
  disabled, // optional
  small, // optional
}: FormInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="mt-3 w-full">
      <label className="text-sm font-normal" htmlFor={name}>
        {label}
      </label>
      {subLabel && <p className="text-[#B8B8B8] text-xs">{subLabel}</p>}
      <div className="relative">
        <input
          className={`h-12 w-full rounded-[4px] border-[1px] border-[#EFEFEF] outline-none py-1 mt-2 pr-[40px] pl-5 ${
            color === "blue" ? "bg-[#335960] border-none" : ""
          }`}
          type={type === "password" && showPassword ? "text" : type}
          // max={new Date().toISOString().split("T")[0]}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          {...register(name, rules)}
        />
        {type === "password" && showPassword && (
          <div
            className="absolute right-6 bottom-3 cursor-pointer"
            onClick={() => setShowPassword(false)}
          >
            <EyeOpen />
          </div>
        )}
        {type === "password" && !showPassword && (
          <div
            className="absolute right-6 bottom-3 cursor-pointer"
            onClick={() => setShowPassword(true)}
          >
            <EyeClose />
          </div>
        )}
      </div>
      {bottomLabel && <p>{bottomLabel}</p>}
      {formState.errors[name] && (
        <p className="text-red-500 text-sm font-normal">
          {formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default FormInput;
