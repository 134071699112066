/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { HomeDataPropType } from "./types";

const initialStateValue: HomeDataPropType = {
  home: {
    banners: [],
    categories: [],
    itemCampaigns: [],
    most_reviewed: [],
    popular: {
      limit: 0,
      offset: 0,
      restaurants: [],
      total_size: 0,
    },
  },
  homeLoading: true,
  detailedRestaurant: [],
  detailedRestaurantLoading: true,
  restaurant: [],
  restaurantLoading: true,
  store: [],
  storeLoading: true,
  supermarket: [],
  supermarketLoading: true,
};

export const homeSlice = createSlice({
  name: "home",
  initialState: { value: initialStateValue },
  reducers: {
    setHome: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    toggleFavorite: (state, action) => {
      const item = state.value.home.popular.restaurants.find(
        (item) => item.id === action.payload
      );
      if (item) {
        item.isFavourite = !item.isFavourite;
      }
    },
    toggleEstablishmentFavorite: (state, action) => {
      const item =
        state.value.restaurant.find((item) => item.id === action.payload) ||
        state.value.store.find((item) => item.id === action.payload) ||
        state.value.supermarket.find((item) => item.id === action.payload);
      if (item) {
        item.isFavourite = !item.isFavourite;
      }
    },
    toggleMostReviewedFavorite: (state, action) => {
      const item = state.value.home.most_reviewed.find(
        (item) => item.id === action.payload
      );
      if (item) {
        item.isFavourite = !item.isFavourite;
      }
    },
    toggleItemFavorite: (state, action) => {
      const item = state.value.detailedRestaurant.find((item) =>
        item.item.find((it) => it.id === action.payload)
      );

      if (item) {
        item.item = item.item.map((it) =>
          it.id === action.payload
            ? { ...it, isFavourite: !it.isFavourite }
            : it
        );
      }
    },
    resetHome: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const {
  setHome,
  resetHome,
  toggleFavorite,
  toggleMostReviewedFavorite,
  toggleItemFavorite,
  toggleEstablishmentFavorite,
} = homeSlice.actions;

export default homeSlice.reducer;
