/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { WalletPropType } from "./types";

const initialStateValue: WalletPropType = {
  wallet: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  walletLoading: true,
  amount: 0,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: { value: initialStateValue },
  reducers: {
    setWallet: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    resetWallet: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setWallet, resetWallet } = walletSlice.actions;

export default walletSlice.reducer;
