import { createSlice } from "@reduxjs/toolkit";
import { OrdersType } from "./types";

const initialStateValue: OrdersType = {
  orders: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  ordersLoading: true,
  detailedOrder: [],
  detailedOrderLoading: true,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: { value: initialStateValue },
  reducers: {
    setOrders: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setOrders } = ordersSlice.actions;
export default ordersSlice.reducer;
