import CryptoJS from "crypto-js";
import { ZIM } from "zego-zim-web";

const appConfig = {
  // appID: 1355313795, // The AppID you get from the ZEGOCLOUD admin console.
  // serverSecret: "20fe855f21f4d991b78df5d798ba75ac", // ServerSecret - type: 32 byte length string
  appID: 846753220, // The AppID you get from the ZEGOCLOUD admin console.
  serverSecret: "27933668fde2ea2320b24c7663ac58cf",
};

export const avatarPrefix = "https://storage.zego.im/zim/example/web/assets/";

var config = {
  // From firebaseConfig
  apiKey: "AIzaSyDGe-26-XTmA12o8v_fBz1-PmEhAvpRbx8",
  authDomain: "touramp-375aa.firebaseapp.com",
  databaseURL: "https://touramp-375aa-default-rtdb.firebaseio.com",
  projectId: "touramp-375aa",
  storageBucket: "touramp-375aa.appspot.com",
  messagingSenderId: "719169076753",
  appId: "1:719169076753:web:562476c0cf25d17ecdfbb0",
  measurementId: "G-L3DHBZNR1D",

  // From Web Push certificates
  vapidKey:
    "BPFFmP-ie183iJsxhCJ5kRYgQXfRjd3Ri9pXRsNwcVDGUW351b-V2HbM8OdoI7gNpzw0nDXDmhnqoblrANm0HjM",
};

ZIM.create(appConfig);
export const zim = ZIM.getInstance();
export const SDKVersion = ZIM.getVersion();

// @ts-ignore
window.zim = zim;

export function generateToken(userID: string, seconds: number): string {
  if (!userID) throw new Error("generateToken error: params invalid.");

  const time = (Date.now() / 1000) | 0;
  const body = {
    app_id: Number(appConfig.appID),
    user_id: String(userID),
    nonce: (Math.random() * 2147483647) | 0,
    ctime: time,
    expire: time + Number(seconds || 7200),
  };

  const key = CryptoJS.enc.Utf8.parse(appConfig.serverSecret);
  let iv = Math.random().toString().substring(2, 18);
  if (iv.length < 16) iv += iv.substring(0, 16 - iv.length);

  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  }).toString();
  const ciphert = Uint8Array.from(
    Array.from(atob(ciphertext)).map((val) => val.charCodeAt(0))
  );
  const len_ciphert = ciphert.length;

  const uint8 = new Uint8Array(8 + 2 + 16 + 2 + len_ciphert);
  // expire: 8
  uint8.set([0, 0, 0, 0]);
  uint8.set(new Uint8Array(Int32Array.from([body.expire]).buffer).reverse(), 4);
  // iv length: 2
  uint8[8] = iv.length >> 8;
  uint8[9] = iv.length - (uint8[8] << 8);
  // iv: 16
  uint8.set(
    Uint8Array.from(Array.from(iv).map((val) => val.charCodeAt(0))),
    10
  );
  // ciphertext length: 2
  uint8[26] = len_ciphert >> 8;
  uint8[27] = len_ciphert - (uint8[26] << 8);
  // ciphertext
  uint8.set(ciphert, 28);

  const token = `04${btoa(String.fromCharCode(...Array.from(uint8)))}`;
  localStorage.setItem("zego-token", token);
  console.log("generateToken", iv.length, body, token);

  return token;
}
