import landingReducer from "./features/landing/reducer";
import homeReducer from "./features/home/reducer";
import favouriteReducer from "./features/favourites/reducer";
import profileReducer from "./features/profile/reducer";
import userReducer from "./features/login/reducer";
import walletReducer from "./features/wallet/reducer";
import riderKycReducer from "./features/riderkyc/reducer";
import cartReducer from "./features/cart/reducer";
import chatReducer from "./features/chat/reducer";
import ordersReducer from "./features/orders/reducer";
import searchReducer from "./features/search/reducer";
import languageReducer from "./features/language/reducer";
import notificationReducer from "./features/notifications/reducer";

const rootReducer = {
  landing: landingReducer,
  home: homeReducer,
  user: userReducer,
  chat: chatReducer,
  riderKyc: riderKycReducer,
  favourite: favouriteReducer,
  language: languageReducer,
  search: searchReducer,
  cart: cartReducer,
  wallet: walletReducer,
  orders: ordersReducer,
  profile: profileReducer,
  notification: notificationReducer,
};

export default rootReducer;
