import { createSlice } from "@reduxjs/toolkit";
import { SearchPropType } from "./types";

const initialStateValue: SearchPropType = {
  establishment: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  product: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  searchLoading: true,
  searchItemLoading: true,
};

export const searchSlice = createSlice({
  name: "search",
  initialState: { value: initialStateValue },
  reducers: {
    setSearch: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setSearch } = searchSlice.actions;
export default searchSlice.reducer;
