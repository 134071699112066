import baseURL from "../baseUrl";
import { timeZone } from "../constants";
import getAccessToken from "../../accessToken";
import { AddAddressParameter, UpdateAddressParameter } from "./types";

class Address {
  async getAddress() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/address/get-address`, {
      method: "get",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }
  async addAddress(data: AddAddressParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/address/create-address`, {
      method: "post",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async updateAddress(data: UpdateAddressParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/address/update-address/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }
  async deleteAddress(data: number) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/address/delete-address/${data}`, {
      method: "delete",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data }),
    });
    return response.json();
  }
}
const instance = new Address();

export default instance;
