import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import RootLoading from "../components/RootLoading/RootLoading";
import ScrollToTop from "../components/utils/ScrollToTop";

import Redirect from "./Redirect";
import pagesRoute from "./routes/pages";
import controlPanel from "./routes/controlPanel";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "..";
import CartPage from "../features/cart";
import { setCart } from "../features/cart/reducer";
import LoginPopup from "../components/modal/LoginPopup";
import Notification from "../components/Notification/Notification";

const Router = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user.value);

  const cart = useSelector((state: IRootState) => state.cart.value);
  const { isActive, isCheckout } = cart;
  const { loggedIn } = user;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Notification />
      <Toaster />
      <div className="">
        {isCheckout && (
          <LoginPopup
            closeModal={() =>
              dispatch(
                setCart({
                  isCheckout: false,
                })
              )
            }
          />
        )}
        {isActive && (
          <CartPage
            onClose={() =>
              dispatch(
                setCart({
                  isActive: false,
                })
              )
            }
          />
        )}
      </div>
      <Suspense fallback={<RootLoading />}>
        <Routes>
          {pagesRoute.map((route) => {
            if (
              route.meta.protectedRoute &&
              route.meta.role === "user" &&
              loggedIn
            ) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                >
                  {controlPanel.map((cRoute) => (
                    <Route
                      key={cRoute.path}
                      path={cRoute.path}
                      element={
                        <Suspense fallback={<RootLoading />}>
                          <cRoute.component />
                        </Suspense>
                      }
                    />
                  ))}
                </Route>
              );
            }
            if (route.meta.protectedRoute && !loggedIn) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Redirect redirectUrl={route.meta.redirectUrl} />}
                />
              );
            }

            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
