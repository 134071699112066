/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LandingReducer } from "./types";

const initialStateValue: LandingReducer = {
  type: "customers",
};

export const landingSlice = createSlice({
  name: "landing",
  initialState: { value: initialStateValue },
  reducers: {
    setLanding: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setLanding } = landingSlice.actions;

export default landingSlice.reducer;
