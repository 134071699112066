import { createSlice } from "@reduxjs/toolkit";
import { RideyKycData } from "./types";

const initialStateValue: RideyKycData = {
  other_vehicle_details: {
    country_id: 0,
    dob: "",
    hours_per_day: "",
    how_long_with_touramp: "",
    occupation: "",
    outright_owner_of_vehicle: "",
    previous_work: "",
    state_id: 0,
    vehicle_chasis: "",
  },
  personal: {
    identification_back_image: "",
    identification_front_image: "",
    identification_type: "",
    profile_image: "",
    ssn_nin_number: "",
  },
  vehicle_brand_id: 0,
  vehicle_image: {
    vehicle_back_image: "",
    vehicle_color: "",
    vehicle_front_image: "",
    vehicle_insurance: "",
    vehicle_left_image: "",
    vehicle_plate_number: "",
    vehicle_proof_of_ownership: "",
    vehicle_right_image: "",
    vehicle_year: "",
  },
  vehicle_model_id: 0,
  vehicle_type: "",
};

export const riderKycSlice = createSlice({
  name: "rideyKyc",
  initialState: { value: initialStateValue },
  reducers: {
    setRiderKyc: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setRiderKyc } = riderKycSlice.actions;
export default riderKycSlice.reducer;
