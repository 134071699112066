import { useState } from "react";
import Close from "../../assets/icons/close.svg";

type PropTYpe = {
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  width?: boolean;
  small?: boolean;
  showClose?: boolean;
};

const Popup = ({ onClose, children, title, width, small, showClose }: PropTYpe) => {
  const [isClosing, setIsClosing] = useState(false);
  const handleBackgroundClick = (event: any) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300); // 300ms is the duration of the CSS transition
  };
  return (
    <div
      className="fixed flex flex-col w-full h-full z-[2147483009] scrollbar-thin overflow-y-scroll bg-trans5  inset-0  transition-opacity"
      onClick={handleBackgroundClick}
    >
      <div
        className={` ${width ? "md:w-[30%]" : ""} ${
          small ? "md:w-fit" : ""
        } relative bg-white mt-auto md:my-[5%] mx-auto w-full md:w-[600px] rounded-tr-lg rounded-tl-lg md:rounded-[24px]`}
      >
        <div className=" w-full p-5 border-b">
          <div className="space font-semibold text-xl text-center">{title}</div>
          {
            !showClose &&
          <div className="absolute p-2 top-5 right-7 cursor-pointer bg-primary rounded-full">
            <img src={Close} alt="" onClick={onClose} />
          </div>
          }
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;
